import styles from './Footer.module.css'

type FooterProps = {
    policy?: boolean
}
const Footer = (props : FooterProps) => {
    const {policy = true} = props
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <h1>Контакты</h1>
                <div className={styles.info}>
                    <p>Реквизиты компании</p>
                    <p><img src={"/email_icon.svg"} alt={"email"}/> help@cybertech-it.ru</p>
                </div>
                <div className={styles.infoBlocks}>
                    <div className={styles.block}>
                        <div className={styles.item}>
                            <p className={styles.title}>Юридический адрес:</p>
                            <p>123122, г. Москва, Пресненская набережная, д.12, <br/> пом. 15Н/29</p>
                        </div>
                        <div className={styles.item}>
                            <p className={styles.title}>Полное наименование: </p>
                            <p>Общество с ограниченной ответственностью «Сайбертех»</p>
                        </div>
                        <div className={styles.item}>
                            <p className={styles.title}>Сокращенное наименование: </p>
                            <p>ООО «Сайбертех»</p>
                        </div>
                    </div>
                    <div className={styles.block}>
                        <div className={styles.item}>
                            <p className={styles.title}>Почтовый адрес:</p>
                            <p>123122, г. Москва, Пресненская набережная, д.12, пом. 15Н/29</p>
                        </div>
                        <div className={styles.item}>
                            <p className={styles.title}>ИНН/КПП:</p>
                            <p>9703155452/770301001</p>
                        </div>
                        {policy &&
                            <div className={styles.item}>
                                <p className={styles.title}>Расчетный счет:</p>
                                <p>40702810940000019190</p>
                            </div>
                        }

                    </div>
                    {policy &&
                        <div className={styles.block}>
                            <div className={styles.item}>
                                <p className={styles.title}>БИК:</p>
                                <p>044525225</p>
                            </div>
                            <div className={styles.item}>
                                <p className={styles.title}>К/С:</p>
                                <p>30101810400000000225</p>
                            </div>
                            <div className={styles.item}>
                                <p className={styles.title}>Наименование банка:</p>
                                <p>ПАО СБЕРБАНК</p>
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.footer}>
                    <p>Copyright 2022-2024</p>
                    <p>Сайбертех All Rights Reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;