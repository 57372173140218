import styles from './Floor.module.css'
import {useNavigate} from "react-router-dom";
import {MAIN, PRIVACY} from "../../../../../utils/constants/routes";
import {ABOUT, CASES, SERVICES} from "../../../../../utils/constants/refs";
const Floor = () => {
    const navigate = useNavigate();
    const handleNavigate = (url : string) => {
        navigate(url)
        window.scrollTo(0, 0);
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.leftBlock}>
                <img
                    onClick={() => handleNavigate(MAIN)}
                    src={"/logo.svg"}
                    alt={"logo"}
                />
                Высококачественное программное обеспечение является ключом к вашему пути цифрового преобразования и конкурентного преимущества
            </div>
            <div className={styles.rightBlock}>
                <a href={ABOUT} onClick={() => handleNavigate(MAIN)}>О нас</a>
                <a href={SERVICES} onClick={() => handleNavigate(MAIN)}>Сервисы</a>
                <a href={CASES} onClick={() => handleNavigate(MAIN)}>Кейсы</a>
                <a onClick={() => handleNavigate(PRIVACY)}>Политика конфиденциальности</a>
            </div>
        </div>
    )
}

export default Floor