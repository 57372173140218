import styles from './Blocks.module.css'
const Blocks = () => {
    return (
        <div className={styles.wrapper}>
            <div className={`${styles.item} ${styles.ceiling}`}>
                <h3>Дизайн и разработка сайтов</h3>
                <img src={"/design_image.png"} alt={"design"} className={styles.img}/>
                <img src={"/logo_left.svg"} alt={"logo"} className={styles.logo}/>
            </div>
            <div className={`${styles.item} ${styles.floor}`}>
                <h3>Приложения</h3>
                <img src={"/app_image.png"} alt={"app"} className={styles.img}/>
                <img src={"/logo_right.svg"} alt={"logo"} className={styles.logo}/>
            </div>
            <div className={`${styles.item} ${styles.ceiling}`}>
                <h3>Кросс-платформенные решения</h3>
                <img src={"/cross_platform_image.png"} alt={"cross platform"} className={styles.img}/>
                <img src={"/logo_top.svg"} alt={"logo"} className={styles.logo}/>
            </div>
            <div className={`${styles.item} ${styles.floor} ${styles.nonImage}`}>
                <h3>Разработка программного обеспечения</h3>
                <div>
                    <p>Усильте свои бизнес-процессы с помощью наших корпоративных программных решений:</p>
                    <div className={styles.elements}>
                        <p>ERP</p>
                        <p>CRM</p>
                        <p>HRM</p>
                        <p>SCM</p>
                        <p>DMS</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blocks