import styles from './Contact.module.css'
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import Input from "./components/Input/Input";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {PRIVACY} from "../../../../../utils/constants/routes";
import emailjs from '@emailjs/browser';
import {CONTACT_ID} from "../../../../../utils/constants/id";
import {publicKey, serviceId, templateId} from "../../../../../utils/constants/keys";


type TInputs = {
    name : string,
    email : string,
    message : string,
}
const Contact = () => {
    const [checked, setChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const navigate = useNavigate();
    const formMethods = useForm<TInputs>({
        mode: 'onSubmit',
        shouldUnregister: false
    })
    const { handleSubmit, formState : {errors}, reset } = formMethods
    const handleNavigate = (url : string) => {
        navigate(url);
        window.scrollTo(0, 0);
    }
    const handleChecked = () => {
        setChecked((prevState) => !prevState)
    }
    const onSubmit: SubmitHandler<TInputs> = async (data) => {
        if (checked) {
            setLoading(true);
            await emailjs
                .send(
                    String(serviceId),
                    String(templateId),
                    data,
                    String(publicKey))
                .then(() => {
                    setLoading(false);
                    setSuccess(true);
                    setChecked(false);
                    reset()
                })
                .catch()
        }
    }
    return (
        <FormProvider {...formMethods}>
            <div className={styles.wrapper} id={CONTACT_ID}>
                <div className={styles.info}>
                    <h1>Начнем творить<br/>вместе</h1>
                    <h3>Приступим к работе</h3>
                </div>
                <div className={styles.contact}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            title={"Имя"}
                            name={"name"}
                            rules={{
                                minLength: 1,
                                maxLength: 255,
                                required: true
                            }}
                            transform={(text: string | undefined) => {
                                if (text) {
                                    const formattedText = text
                                        .slice(0, 255);
                                    return formattedText
                                }
                                return text
                            }}
                        />
                        <Input
                            title={"E-mail"}
                            name={"email"}
                            rules={{
                                minLength: 5,
                                maxLength: 255,
                                required: true,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            }}
                            transform={(text: string | undefined) => {
                                if (text) {
                                    const formattedText = text
                                        .slice(0, 255);
                                    return formattedText
                                }
                                return text
                            }}
                        />
                        <Input
                            title={"Текст сообщения"}
                            name={"message"}
                            rules={{
                                minLength: 1,
                                maxLength: 255,
                                required: true
                            }}
                            type={"textarea"}
                            transform={(text: string | undefined) => {
                                if (text) {
                                    const formattedText = text
                                        .slice(0, 255);
                                    return formattedText
                                }
                                return text
                            }}
                        />
                        <div className={styles.checkBox}>
                            <img
                                src={checked ? "/checkbox_true.svg" : "/checkbox_false.svg"}
                                onClick={handleChecked}
                                alt={"checkbox"}/>
                            Я согласен с <u onClick={() => handleNavigate(PRIVACY)}>Политикой конфиденциальности</u>
                        </div>
                        {!success && !loading &&
                            <button
                                className={
                                    errors.name ||
                                    errors.email ||
                                    errors.message ||
                                    !checked ? `${styles.button} ${styles.error}` : styles.button}
                                type={"submit"}>
                                Отправить
                            </button>
                        }
                        {loading &&
                            <p className={styles.loader}>
                                <img src={"/loader.png"} alt={"loader"}/>
                            </p>
                        }
                        {success &&
                            <p className={styles.successEmail}>
                            <img src={"/status_icon.svg"} alt={"ok"}/>
                                Сообщение отправлено
                            </p>
                        }

                    </form>
                </div>
            </div>
        </FormProvider>
    )
}

export default Contact;