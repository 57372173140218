import Container from "../components/Container/Container";
import Info from "./components/Info/Info";
import Blocks from "./components/Blocks/Blocks";
import Content from "./components/Content/Content";
import Description from "./components/Description/Description";
const Main = () => {
    return (
            <Container>
                <Info/>
                <Blocks/>
                <Content/>
                <Description/>
            </Container>
    )
}

export default Main