import styles from './Input.module.css'
import React from "react";
import {RegisterOptions, useFormContext} from "react-hook-form";

interface IInputProps {
    title : string;
    name : string;
    rules?: RegisterOptions;
    type?: "textarea" | 'input';
    transform?: (text: string | undefined) => string | undefined | number
}

const Input = (props : IInputProps) => {
    const {title,
        name,
        rules = {},
        type = "input",
        transform} = props;
    const {register, setValue, formState : {errors}} = useFormContext();
    const transformInput = (text: string | undefined) => {
        if (transform && text) {
            setValue(name, transform(text))
        }
    }
    return (
        <div className={styles.inputBlock}>
            {type === "input" ?
                    <input
                        className={errors.name ? `${styles.field} ${styles.error}` :  styles.field}
                        placeholder={title}
                        {...register(name, rules)}
                        onChange={(e) => {
                            transform && transformInput(e.target.value)
                        }}
                    /> :
                    <textarea
                        className={errors.name ? `${styles.field} ${styles.error} ${styles.textArea}` :  `${styles.field} ${styles.textArea}`}
                        placeholder={title}
                        {...register(name, rules)}
                        onChange={(e) => {
                            transform && transformInput(e.target.value)
                        }}
                    />
            }
        </div>


)
}

export default Input