import styles from './Info.module.css'
import {CONTACT} from "../../../../utils/constants/refs";
import {ABOUT_ID} from "../../../../utils/constants/id";
import {useEffect, useState} from "react";

const Info = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className={styles.wrapper} id={ABOUT_ID}>
            <div className={styles.background}>
                <div className={styles.big}>
                    <div className={styles.info}>
                        <img src={isMobile ? '/logo_background_mobile.svg' : '/logo_background.png'} alt={"logo"}/>
                        <h1>Входим в реестр компаний<br/>Российского программного<br/>обеспечения</h1>
                        <p>Охватите потребности вашего бизнеса с помощью разработки программного обеспечения для
                            предприятия</p>
                    </div>
                </div>
                <div className={`${styles.little} ${styles.left}`}>
                    <a className={`${styles.circle} ${styles.block}`} href={CONTACT}>
                        <img src={"/arrow.svg"} alt={"circle"}/>
                        Начать работать <br/>с нами
                    </a>
                </div>
                <div className={`${styles.little} ${styles.right}`}>
                </div>
            </div>
            <div className={styles.block}>
                <div className={styles.item}>
                    JavaScript
                </div>
                <div className={styles.item}>
                    UI/UX design
                </div>
                <div className={styles.item}>
                    IOS
                </div>
                <div className={styles.item}>
                    ERP
                </div>
                <div className={styles.item}>
                    CRM
                </div>
                <div className={styles.item}>
                    Android
                </div>
                <div className={styles.item}>
                    SCM
                </div>
                <div className={styles.item}>
                    DevOps
                </div>
            </div>
            <a className={`${styles.circle} ${styles.outside}`} href={CONTACT}>
                <img src={"/arrow.svg"} alt={"circle"}/>
                Начать работать <br/>с нами
            </a>
        </div>
    )
}

export default Info;