import styles from "./Content.module.css";
import {CONTACT} from "../../../../utils/constants/refs";
import {SERVICES_ID} from "../../../../utils/constants/id";
import {useEffect, useRef, useState} from "react";
const Content = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1024)
    const boxInnerRef  = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleArrowClick = (direction : "right" | "left") => {
        const boxInner = boxInnerRef.current;
        if (boxInner) {
            const x = direction === 'right' ? boxInner.clientWidth / 2 : -boxInner.clientWidth / 2;
            boxInner.scrollTo({
                left: boxInner.scrollLeft + x,
                behavior: 'smooth',
            });
        }
    };
    const blocks = [
        <a className={`${styles.block} ${styles.littleBlock}`}>
            <img src={"/analytics_image.png"} alt={"analytics"}/>
            <h4>Аналитика</h4>
            <p>Наша команда цифрового агентства предлагает вам всесторонний анализ вашего веб-трафика, поведения
                пользователей и эффективности кампаний. Получайте ценные инсайты, оптимизируйте контент</p>
        </a>,
        <a className={styles.block}>
            <img src={"/business_idea_image.png"} alt={"business idea"}/>
            <h4>Создание бизнес-модели</h4>
            <p>Основываясь на уникальных потребностях вашего бизнеса, мы разрабатываем инновационные бизнес-модели,
                точно соответствующие требованиям цифровой среды. Наше цифровое агентство стремится максимизировать
                ваш успех через кастомизированные стратегии монетизации, цифровые продукты и внимательный анализ
                конкурентоспособности.
                Доверьте нам построение устойчивой бизнес модели, которая будет успешной в мире цифровых
                возможностей</p>
        </a>,
        <a className={styles.block}>
            <img src={"/demonstration_image.png"} alt={"demonstration"}/>
            <h4>Демонстрация продукта</h4>
            <p>Наши цифровые эксперты предоставят вам виртуальный тур по инновационным решениям, предлагаемым нашим
                агентством</p>
        </a>,
        <a className={styles.block}>
            <img src={"/business_boost_image.png"} alt={"boost"}/>
            <h4>Усиление вашего бизнеса</h4>
            <p>Мы воплощаем ваш бизнес в мир цифровых возможностей, обеспечив усиление его присутствия и
                эффективности</p>
        </a>,
        <a className={styles.block}>
            <img src={"/optimisation_image.png"} alt={"optimisation"}/>
            <h4>Оптимизация процессов</h4>
            <p>Наши цифровые стратегии призваны не только улучшить текущие процессы, но и подготовить ваш бизнес к
                динамичному будущему</p>
        </a>
    ]
    return (
        <div className={styles.wrapper} id={SERVICES_ID}>
            <div className={styles.header}>
                <h1>Цифровые решения для вашего бизнеса</h1>
                <div className={styles.info}>
                    <h4>Как мы работаем</h4>
                    <p>Мы расширяем возможности корпоративных предприятий для масштабирования и получения
                        конкурентного преимущества</p>
                    <a href={CONTACT}>
                        Связаться с нами
                        <img src={"/arrow_circle.svg"} alt={"arrow"}/>
                    </a>
                </div>
            </div>
            {
                isMobile ?
                    <div className={styles.blockWrapper}>
                        <div className={styles.blocks} ref={boxInnerRef}>
                            {blocks}
                        </div>
                        <div className={styles.arrowsBlock}>
                            <img
                                src={'/arrow_left.png'}
                                alt={"left"}
                                className={styles.arrow}
                                onClick={() => handleArrowClick('left')}
                            />
                            <img
                                src={'/arrow_right.png'}
                                alt={"right"}
                                className={styles.arrow}
                                onClick={() => handleArrowClick('right')}
                            />
                        </div>
                    </div> :
                    <div className={styles.content}>
                        <div className={styles.topBlocks}>
                            {blocks[0]}
                            {blocks[1]}
                        </div>
                        <div className={styles.bottomBlocks}>
                            {blocks[2]}
                            {blocks[3]}
                            {blocks[4]}
                        </div>
                    </div>
            }
        </div>
    )
}

export default Content