import styles from './Container.module.css'
import {ReactNode} from "react";
import Header from "./components/Header/Header";
import Contact from "./components/Contact/Contact";
import Floor from "./components/Floor/Floor";
import Footer from "./components/Footer/Footer";


const Container = (props : {children: ReactNode}) => {
    const {children} = props
    return (
        <>
            <div className={styles.wrapper}>
                <Header/>
                {children}
                <Contact/>
                <Floor/>
            </div>
            <Footer/>
        </>

    )
}

export default Container;