import styles from './Header.module.css'
import {useNavigate} from "react-router-dom";
import {MAIN, PRIVACY} from "../../../../../utils/constants/routes";
import {ABOUT, CASES, CONTACT, SERVICES} from "../../../../../utils/constants/refs";
import {useEffect, useState} from "react";

const Header = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1033)
    const [open, setOpen] = useState<boolean>(false)
    useEffect(() => {
        const handleScroll = () => {
            if (open) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'visible';
            }
        };
        const handleEscKeyPress = (event : KeyboardEvent) => {
            if (event.key === 'Escape') {
                setOpen(false)
            }
        };
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('keydown', handleEscKeyPress);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('keydown', handleScroll);
        };
    }, [open])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
            setOpen(false)
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNavigate = (url : string) => {
        navigate(url)
        window.scrollTo(0, 0);
        setOpen(false);
    }
    if (isMobile) {
        return (
                <>
                    <div className={open ? `${styles.mobileWrapper} ${styles.fix}` : styles.mobileWrapper}>
                        <img
                            className={styles.logoImg}
                            onClick={() => handleNavigate(MAIN)}
                            src={'./logo.svg'}
                            alt={"logo"}/>
                        <img
                            onClick={() => setOpen(prevState => !prevState)}
                            className={styles.menuImg}
                            src={open ? './cross.png' : './menu.png'}
                            alt={"menu"}
                        />
                    </div>
                    {open &&
                        <div className={styles.background}>
                            <div className={styles.menu}>
                                <center>
                                    <div className={styles.body}>
                                        <div className={styles.block}>
                                            <a href={ABOUT} onClick={() => handleNavigate(MAIN)}>О нас</a>
                                            <a href={SERVICES} onClick={() => handleNavigate(MAIN)}>Сервисы</a>
                                            <a href={CASES} onClick={() => handleNavigate(MAIN)}>Кейсы</a>
                                            <a onClick={() => handleNavigate(PRIVACY)}>Политика конфиденциальности</a>
                                        </div>
                                    </div>
                                    <a className={styles.button} href={CONTACT}>Связаться</a>
                                </center>
                            </div>
                        </div>
                    }
                </>
        )
    }
    return (
        <div className={styles.wrapper}>
            <img
                onClick={() => handleNavigate(MAIN)}
                src={'./logo.svg'}
                alt={"logo"}/>
            <div className={styles.ways}>
                <a href={ABOUT} onClick={() => handleNavigate(MAIN)}>О нас</a>
                <a href={SERVICES} onClick={() => handleNavigate(MAIN)}>Сервисы</a>
                <a href={CASES} onClick={() => handleNavigate(MAIN)}>Кейсы</a>
                <a onClick={() => handleNavigate(PRIVACY)}>Политика конфиденциальности</a>
            </div>
            <a className={styles.button} href={CONTACT}>Связаться</a>
        </div>
    )
}

export default Header