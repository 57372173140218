import React from 'react';
import './App.css';
import Main from "./pages/Main/Main";
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import Policy from "./pages/Policy/Policy";
import {MAIN, PRIVACY} from "./utils/constants/routes";

function App() {
  return (
            <Router>
                <Routes>
                    <Route path={'/'}>
                        <Route path={MAIN} element={<Main/>}/>
                        <Route path={PRIVACY} element={<Policy/>}/>
                        <Route path="/*" element={<Navigate to={MAIN}/>} />
                        <Route path="/" element={<Navigate to={MAIN}/>} />
                    </Route>
                </Routes>
            </Router>
  );
}

export default App;
