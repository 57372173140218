import styles from './Description.module.css'
import {CASES_ID} from "../../../../utils/constants/id";
const Description = () => {
    return (
        <div className={styles.wrapper} id={CASES_ID}>
            <div className={styles.header}>
                <h1>Мы знаем, что делаем</h1>
                <p>
                    Взгляните на реальные примеры того, как наши цифровые стратегии и решения трансформировали бизнесы.
                    У каждого кейса — свой уникальный вызов, своя эффективная стратегия и впечатляющий результат.
                    Погрузитесь в наши проекты и убедитесь сами, как мы усиливаем бренды в цифровой эпохе
                </p>
            </div>
            <div className={styles.content}>
                <div className={styles.leftBlocks}>
                    <div className={`${styles.block} ${styles.big}`}>
                            <div className={`${styles.items} ${styles.big}`}>
                                <span>Python</span>
                                <span>Django</span>
                                <span>React</span>
                                <span>JavaScript</span>
                            </div>
                            <img src={"/crm_image.png"} alt={"crm"}/>
                            <h1 className={styles.title}>CRM: Продажа авиационных систем</h1>
                            <p className={styles.text}>
                                Система управления взаимоотношениями с клиентами, которая позволяет:
                                ускорять процесс оформления продажи запчастей, улучшать качество взаимодействия
                                с клиентом за счет более быстрых ответов
                            </p>
                    </div>
                    <div className={`${styles.block} ${styles.little}`}>
                            <img src={"/factory_image.png"} alt={"factory"}/>
                            <div className={styles.littleBlockInfo}>
                                <div className={styles.littleBlockText}>
                                    <h1 className={styles.title}>Cyber-Factory</h1>
                                    <p className={styles.text}>
                                        Приложение для продажи куккурузы и связи фермеров и фабрик,
                                        маркетплейс с базой знаний на Android и iOS
                                    </p>
                                </div>
                                <div className={`${styles.items} ${styles.little}`}>
                                    <span>Python</span>
                                    <span>FastAPI</span>
                                    <span>React</span>
                                    <span>JavaScript</span>
                                </div>
                            </div>

                    </div>
                </div>
                <div className={styles.rightBlocks}>
                    <div className={`${styles.block} ${styles.little}`}>
                        <img src={"/agro_image.png"} alt={"agro"}/>
                        <div className={styles.littleBlockInfo}>
                            <div className={styles.littleBlockText}>
                                <h1 className={styles.title}>Cyber-Agro</h1>
                                <p className={styles.text}>
                                    Проведена цифровизация закупки сельхозпродукции для холдинга
                                    агропромышленности и разработан личный кабинет для поставщиков
                                </p>
                            </div>
                                <div className={`${styles.items} ${styles.little}`}>
                                    <span>PHP</span>
                                    <span>Swift</span>
                                    <span>Python</span>
                                    <span>1C</span>
                                    <span>Kotlin</span>
                                    <span>Angular</span>
                                </div>
                        </div>
                    </div>
                    <div className={`${styles.block} ${styles.big}`}>
                        <div className={`${styles.items} ${styles.big}`}>
                            <span>PHP</span>
                            <span>C++</span>
                        </div>
                        <img src={"/control_image.png"} alt={"control"}/>
                        <h1 className={styles.title}>Cyber-Control</h1>
                        <p className={styles.text}>
                            Система для учёта рабочего времени сотрудников, распознавания активности и
                            вредоносной деятельности и аналитики их работы, а также формирования отчетности
                            для руководства в удобной графической форме.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Description;